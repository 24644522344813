import React from 'react'
import HeaderPaginas from '../../../components/headerPaginas'
import Layout from '../../../components/layout'
import Seo from '../../../components/SEO/SEO'
import CtaServicios from '../../../components/ctaServicios'

export default function Integracion(props) {
    return (
        <Layout>
            <Seo title="Sistemas informáticos" pathname={props.location.pathname} />
            <div className="cabecera-servicio">
                <HeaderPaginas titulo="Sistemas informáticos" imagen="/img/cabeceras/sistemas-avanzados-bullhost.jpg" />
                <div className="intro-servicio">
                    <p>En Bullhost contamos con un equipo de técnicos de campo e indoor para dotar a nuestros clientes de los servicios de un departamento de sistemas potente e innovador. </p>
                </div>
            </div>
            <div className="cuerpo-servicio">
                <section className="limitador">
                    <p className="parrafo-destacado">Nuestro equipo proviene de potentes empresas de informática y sistemas avanzados, ahora lo damos todo en Bullhost.</p>
                </section>
                <section className="">
                    <div className="limitador contenido__servicio">
                        <h2 className="titulo_contenido">Área de Ingeniería IT</h2>
                        <div>
                            <h3>Consultoría tecnológica</h3>
                            <p>Saber y conocer el estado de tus sistemas informáticos, qué mejorar y hacia donde evolucionar es parte de la tarea en este área.</p>
                        </div>
                        <div>
                            <h3>Integración de tecnologías</h3>
                            <p>Los sistemas informáticos y tecnológicos de una empresa son un ecosistema complejo e interconectado, frecuentemente no se hace uso de una única tecnología, sino de varias, y la continua formación y certificación de nuestro equipo con diferentes soluciones y fabricantes es un garante para afrontar dichas integraciones. Si estás pensando en un proyecto que integra diferentes soluciones y con diferentes objetivos, consúltanos, algunos ejemplos podrían ser:</p>
                            <ul>
                                <li>Proyectos multisede</li>
                                <li>Integración de tecnologías local-cloud</li>
                                <li>Hibridación multitecnologías</li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="bloques-partidos">
                    <section className="bloque-oscuro">
                    <div className="limitador contenido__servicio">
                        <h2 className="titulo_contenido">Proyectos llave en mano</h2>
                        <p>Comenzar en una toma de datos y finalizar en la entrega al cliente es de gente poco común, pero nosotros lo somos, y tenemos la capacidad de ofrecer a nuestros clientes la gestión completa de un proyecto IT, asumiendo además, si fuera necesario:</p>
                        <ul>
                            <li>Gestión de proveedores</li>
                            <li>Control presupuestario</li>
                        </ul>
                        </div>
                    </section>
                    <section className="bloque-acento" id="bloque-redes">
                        <div className="overlay-rojo"></div>
                        <div className="limitador contenido__servicio">
                        <h2 className="titulo_contenido">Área de redes y comunicaciones</h2>
                        <p>Conectar y generar infraestructuras conectadas seguras es parte de nuestro trabajo: switching, redes privadas virtuales (VPN) para la unión de sedes, firewalls... </p>
                        </div>
                    </section>
                </section>
                <section className="limitador contenido__servicio">
                    <h2 className="titulo_contenido">Mantenimientos informáticos</h2>
                    <p>Bullhost mantiene y gestiona todas la insfraestructuras de nuestros clientes, tanto las propias en sus instalaciones como aquellas soluciones en nuestra nube:</p>
                    <ul>
                        <li>Hosting</li>
                        <li>Housing</li>
                        <li>Infraestructuras IaaS</li>
                        <li>Soluciones de Almacenamiento y Backup Remoto</li>
                        <li>Soluciones de Voz/IP en la nube</li>
                        <li>Soluciones de red y seguridad</li>
                    </ul>
                </section>
            </div>
            <CtaServicios/>
        </Layout>
    )
}
